import React from 'react';

const Advisor = () => {
  return (
    <div>
          <div className="takeaways">
            <h2>Key Takeaways</h2>
            <ul>
              <li>Mortgage brokers (or mortgage advisers) could save you time, effort and money</li>
              <li>
              Mortgage brokers have access to search the market to give you unbiased expert advice and find the best deal for you, not tied to one lender
              </li>
              <li>
              Most mortgage brokers have access to exclusive deals and may be able to negotiate with the lender for you
              </li>
              <li>
              A mortgage broker's job is to help you avoid having your remortgage application rejected
              </li>
            </ul>
          </div>
          <div className="transcript">
            <h2>Transcript</h2>
            <p>
              The benefits of switching via a mortgage adviser.<br/><br/>
              The mortgage broker can potentially save you time, effort and money.<br /><br />
              Number one, get the best deal. The mortgage broker can search the market on your behalf and provide unbiased advice as they are not tied to one particular lender.<br /><br />
              Number two, expert advice. They will take time to understand your long-term goals to recommend the most suitable products.<br /><br />
              Number three, better interest rate. Mortgage brokers often have access to exclusive deals and rates that aren't available to the general public. This is because they have special relationships with lenders and can negotiate lower interest rates on your behalf.<br /><br />
              Number four, avoid being declined for a mortgage. Mortgage brokers assess your application and financial information upfront to determine whether you are eligible or not.<br /><br />
              Number five, save time. The mortgage broker will do the hard work for you. The process can be time-consuming and applying during office hours can be challenging. The mortgage broker will deal with all the paperwork and chase for updates on your behalf.<br /><br />
            </p>
          </div>
    </div>
  );
};

export default Advisor;
